import { Badge, Modal } from "flowbite-react";
import React, { useState } from "react";
import { DocumentView } from "../../../app_client";
import { useProject } from "../State";
import { YMButton } from "../../../ym-components";

export const ExportDocumentModal = ({
  doc,
  onCloseModal,
  onExportDocument,
}: {
  doc: DocumentView | undefined;
  onCloseModal: () => void;
  onExportDocument: (doc: DocumentView, dimensions: String[]) => void;
}) => {
  const { projectDimensions } = useProject();
  const [selectedDimensions, setSelectedDimensions] = useState<String[]>([]);

  const toggleDimension = (dimension: String) => {
    if (selectedDimensions.indexOf(dimension) == -1) {
      setSelectedDimensions([...selectedDimensions, dimension]);
    } else {
      setSelectedDimensions(selectedDimensions.filter((d) => d !== dimension));
    }
  };

  return (
    <>
      <Modal
        root={document.body}
        size="5xl"
        show={doc !== undefined}
        onClose={onCloseModal}
      >
        <Modal.Header>
          <div className={"font-bold text-indigo-800 title"}>
            {" "}
            {doc?.document_name}
            {doc?.document_extension}
          </div>
        </Modal.Header>
        <Modal.Body>
          <p>
            {" "}
            Sélectionnez les dimensions à inclure lors de l'export du document{" "}
          </p>
          <div className={"flex flex-wrap gap-2"}>
            {projectDimensions.map((dimension) => (
              <Badge
                className={"cursor-pointer py-1"}
                color={
                  selectedDimensions.indexOf(dimension.name) == -1
                    ? "gray"
                    : "info"
                }
                size="xs"
                onClick={() => toggleDimension(dimension.name)}
              >
                {dimension.name}
              </Badge>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {doc && (
            <YMButton
              text={"Exporter"}
              onClick={() => onExportDocument(doc, selectedDimensions)}
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};