import { createContext, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AppCLient, Permissions } from "./app_client";

export interface AuthUser {
  token: string;
  workspaceId: string;
  workspaceName?: string;
  workspaceToken?: number;
  setWorkspaceId: (workspaceId: string) => void;
  permissions: Permissions[];
}

export const AuthUserContext = createContext({
  token: "",
  workspaceId: "",
  workspaceName: "",
  workspaceToken: undefined,
  setWorkspaceId: (workspaceId: string) => {
  },
  permissions: [],
} as AuthUser);

export function TokenContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {

  const [Context, setContext] = useState({
    token: "",
    workspaceId: "",
    workspaceName: "",
    setWorkspaceId: (workspaceId: string) => {
    },
    permissions: [] as Permissions[],
  });

  const { getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!Context.token && isAuthenticated && !isLoading) {
      getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }).then((newToken) => {
        let client = new AppCLient({
          BASE: process.env.REACT_APP_APP_API_HOST,
          TOKEN: newToken,
        });

        const handleWorkspaceChange = async (workspaceId: string) => {
          setContext((prev) => ({
            ...prev,
            workspaceId: workspaceId,
          }));
          const workspace =
            await client.workspace.getWorkspaceWorkspaceWorkspaceIdGet(
              workspaceId
            );
          setContext((prev) => ({
            ...prev,
            workspaceName: workspace.owner_email,
            workspaceToken: workspace.token_balance,
          }));
        };

        client.workspace
          .createOrGetWorkspaceWorkspacePost({})
          .then((workspace) => {
            client.userInfo.getMeInfoMePermissionsGet().then((permissions) => {
              setContext((prev) => ({
                ...prev,
                token: newToken,
                workspaceId: workspace.workspace_id,
                permissions: permissions,
                setWorkspaceId: handleWorkspaceChange,
              }));
              handleWorkspaceChange(workspace.workspace_id);
            });
          });
      });
    }
  }, [getAccessTokenSilently, isLoading, isAuthenticated]);

  return (
    <AuthUserContext.Provider value={Context}>
      {children}
    </AuthUserContext.Provider>
  );
}

export const useAuthenticatedClient = function (): AppCLient | null {
    const { token } = useContext(AuthUserContext) as {
      token: string;
      workspaceId: string;
      permissions: string[];
    };
    const [client, setClient] = useState(null as AppCLient | null);

    useEffect(() => {
        if (token) {
            setClient(
                new AppCLient({
                BASE: process.env.REACT_APP_APP_API_HOST,
                TOKEN: token,
                })
            );
        }
    }, [token]);

    return client;
}
