import React, { useContext } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ApiProvider } from "./api";
import { Sidebar } from "flowbite-react/lib/esm/components/Sidebar";
import { HiClock, HiLightBulb, HiOutlineAnnotation } from "react-icons/hi";
import Events from "./pages/Events";
import TopicModeling from "./pages/TopicModeling";
import TopicModelingStopWords from "./pages/TopicModelingStopWords";
import ProjectList from "./pages/project/project-list/ProjectList";
import ProjectVerbatimsPage from "./pages/project/ProjectVerbatimsPage";
import {
  SideBarItemCollaps,
  SiderBarItem,
} from "./app_client/components/SideBar";
import { MdCollectionsBookmark } from "react-icons/md";
import ProjectPatterns from "./pages/project/lexicon/ProjectPatterns";
import { SiDatabricks } from "react-icons/si";
import PatternMatchingModelList from "./pages/pattern-matching-models/List";
import PatternMatchingModelPage from "./pages/pattern-matching-models/ModelPage";
import ProjectNLPHistory from "./pages/project/ProjectNLPHistory";
import ProjectStatistics from "./pages/project/ProjectStatistics";
import ProjectManualAnnotations from "./pages/project/ProjectManualAnnotations";
import { ProjectPanel } from "./pages/project/Pannel";
import Inference from "./pages/Inference";
import { ProjectFrequencyGraphPage } from "./pages/project/ProjectFrequencyGraph";
import ProjectCrossAnalyse from "./pages/project/ProjectCrossAnalyse";
import ProjectTopics from "./pages/project/ProjectTopics";
import { AuthUserContext, TokenContextProvider } from "./AuthUserContext";
import { Permissions } from "./app_client";
import { YMTopBanner, YMWorkspaceInfo } from "./ym-components";
import WorkspaceList from "./pages/workspace/List";
import { VscOrganization } from "react-icons/vsc";
import Workspace from "./pages/workspace/Workspace";
import JoinWorkspace from "./pages/workspace/JoinWorkspace";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import DocumentChunkList from "./pages/debugging/DocumentChunkList";
import { BsBug } from "react-icons/bs";
import { ProjectDocuments } from "./pages/project/project-sources/ProjectDocuments";
import ProjectSemanticSearch from "./pages/project/lexicon/ProjectSemanticSearch";
import ProjectTagsSearch from "./pages/project/search-tags/ProjectSearchTags";
import ProjectFewShotLearning from "./pages/project/few-shot-learning/FewShotLearning";
import ImportModels from "./pages/project/import_models/ImportModels";
import ApiRateLimitInfo from "./pages/debugging/ApiRateLimitInfo";
import FewShotLearningModels from "./pages/fewshot-learning-models/List";
import FewShotLearningModelView from "./pages/fewshot-learning-models/View";

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // @ts-ignore
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    // @ts-ignore
    return this.props.children;
  }
}

if (
  !process.env.REACT_APP_AUTH0_DOMAIN ||
  !process.env.REACT_APP_AUTH0_CLIENT_ID ||
  !process.env.REACT_APP_AUTH0_AUDIENCE
) {
  console.error(
    "The application cannot be mounted as some environment variables are missing."
  );
} else {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  const Panel = (props: any) => {
    const tokenContext = useContext(AuthUserContext);
    const { permissions } = tokenContext;

    const isFactoryUser =
      permissions.includes(Permissions.INFERENCE_FULL) &&
      permissions.includes(Permissions.TOPIC_MODELING_FULL) &&
      permissions.includes(Permissions.JOB_FULL) &&
      permissions.includes(Permissions.KERNEL_FULL);

    const isWorkspaceManage = permissions.includes(Permissions.WORKSPACE_FULL);

    const isDeveloper = permissions.includes(Permissions.DEVELOPER_FULL);

    return (
      <div className="flex h-full overflow-hidden dark:bg-gray-900">
        <YMTopBanner />
        <Sidebar
          className="fixed pt-14 top-0 left-0 z-40 border-r border-gray-200 w-64 bg-gradient-to-b bg-white h-screen transition-transform"
          aria-label="Sidebar with logo branding example"
        >
          <Sidebar.Items>
            <Sidebar.Items>
              <YMWorkspaceInfo />
            </Sidebar.Items>

            <Sidebar.ItemGroup>
              <SiderBarItem path="/" icon={<MdCollectionsBookmark />}>
                Projets
              </SiderBarItem>
              <SideBarItemCollaps label="Modèles" icon={<SiDatabricks />}>
                <SiderBarItem path="/pattern_matching_models" icon={null}>
                  Champs Lexicaux
                </SiderBarItem>
                <SiderBarItem path="/few_shot_learning_models" icon={null}>
                  Few Shot Learning
                </SiderBarItem>
              </SideBarItemCollaps>
              {isFactoryUser && (
                <SideBarItemCollaps label="Factory" icon={<HiLightBulb />}>
                  <SiderBarItem path="/topic_modeling" icon={null}>
                    Topic Modeling
                  </SiderBarItem>
                  <SiderBarItem
                    path="/inference"
                    icon={<HiOutlineAnnotation />}
                  >
                    Inference
                  </SiderBarItem>
                  <SiderBarItem path="/topic_modeling/stopwords" icon={null}>
                    Stop words
                  </SiderBarItem>
                  <SiderBarItem path="/events" icon={<HiClock />}>
                    Events
                  </SiderBarItem>
                </SideBarItemCollaps>
              )}
              {isWorkspaceManage && (
                <SiderBarItem path="/workspaces" icon={<VscOrganization />}>
                  Workspaces
                </SiderBarItem>
              )}
              {isDeveloper && (
                <SideBarItemCollaps label="Outils Développeur" icon={<BsBug />}>
                  <SiderBarItem path="/document_chunks">
                    Document chunk parsing
                  </SiderBarItem>
                  <SiderBarItem path="/ratelimits/info">
                    Api Rate limits
                  </SiderBarItem>
                </SideBarItemCollaps>
              )}
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>
        <div className="flex-1 overflow-auto p-0 sm:ml-64 bg-gray-50 min-h-screen w-100">
          <div className="bg-white bg-opacity-20 backdrop-blur-md p-2 rounded-lg shadow-xl min-h-screen mt-20">
            {props.children}
          </div>
        </div>
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Panel>
          <ProjectList />
        </Panel>
      ),
    },
    {
      path: "/login",
      element: (
        <Panel>
          <ProjectList />
        </Panel>
      ),
    },
    {
      path: "/inference",
      element: (
        <Panel>
          <Inference />
        </Panel>
      ),
    },
      {
      path: "/ratelimits/info",
      element: (
        <Panel>
          <ApiRateLimitInfo />
        </Panel>
      ),
    },
    {
      path: "project",
      children: [
        {
          path: "",
          element: (
            <Panel>
              <ProjectList />
            </Panel>
          ),
        },
        {
          path: ":projectId",
          element: (
            <ProjectPanel>
              <ProjectFrequencyGraphPage />
            </ProjectPanel>
          ),
        },
        {
          path: ":projectId/graph",
          element: (
            <ProjectPanel>
              <ProjectFrequencyGraphPage />
            </ProjectPanel>
          ),
        },
        {
          path: ":projectId/cross",
          element: (
            <ProjectPanel>
              <ProjectCrossAnalyse />
            </ProjectPanel>
          ),
        },
        {
          path: ":projectId/verbatims",
          element: (
            <ProjectPanel>
              <ProjectVerbatimsPage />
            </ProjectPanel>
          ),
        },
        {
          path: ":projectId/patterns",
          element: (
            <ProjectPanel>
              <ProjectPatterns />
            </ProjectPanel>
          ),
        },
        {
          path: ":projectId/semantic_search",
          element: (
            <ProjectPanel>
              <ProjectSemanticSearch />
            </ProjectPanel>
          ),
        },
        {
          path: ":projectId/search_tags",
          element: (
            <ProjectPanel>
              <ProjectTagsSearch />
            </ProjectPanel>
          ),
        },
        {
          path: ":projectId/topics",
          element: (
            <ProjectPanel>
              <ProjectTopics />
            </ProjectPanel>
          ),
        },
        {
          path: ":projectId/history",
          element: (
            <ProjectPanel>
              <ProjectNLPHistory />
            </ProjectPanel>
          ),
        },
        {
          path: ":projectId/stats/:statKind/:dimName",
          element: (
            <ProjectPanel>
              <ProjectStatistics />
            </ProjectPanel>
          ),
        },
        {
          path: ":projectId/manual_annotations",
          element: (
            <ProjectPanel>
              <ProjectManualAnnotations />
            </ProjectPanel>
          ),
        },
        {
          path: ":projectId/few_shot_tagging",
          element: (
            <ProjectPanel>
              <ProjectFewShotLearning />
            </ProjectPanel>
          ),
        },
        {
          path: ":projectId/models",
          element: (
            <ProjectPanel>
              <ImportModels />
            </ProjectPanel>
          ),
        },
        {
          path: ":projectId/sources",
          element: (
            <ProjectPanel>
              <ProjectDocuments />
            </ProjectPanel>
          ),
        },
        {
          path: ":projectId/pattern_matching_models/edit",
          element: (
            <ProjectPanel>
              <PatternMatchingModelPage />
            </ProjectPanel>
          ),
        },
      ],
    },
      {
      path: "few_shot_learning_models",
      children: [
        {
          path: "",
          element: (
            <Panel>
              <FewShotLearningModels />
            </Panel>
          ),
        },
        {
          path: "edit",
          element: (
            <Panel>
              <FewShotLearningModelView />
            </Panel>
          ),
        },
      ],
    },
    {
      path: "pattern_matching_models",
      children: [
        {
          path: "",
          element: (
            <Panel>
              <PatternMatchingModelList />
            </Panel>
          ),
        },
        {
          path: "edit",
          element: (
            <Panel>
              <PatternMatchingModelPage />
            </Panel>
          ),
        },
      ],
    },
    {
      path: "topic_modeling",
      children: [
        {
          path: "",
          element: (
            <Panel>
              <TopicModeling />
            </Panel>
          ),
        },
        {
          path: "stopwords",
          element: (
            <Panel>
              <TopicModelingStopWords />
            </Panel>
          ),
        },
      ],
    },
    {
      path: "join_workspace/:workspaceToken",
      element: <JoinWorkspace />,
    },
    {
      path: "workspaces",
      element: (
        <Panel>
          <WorkspaceList />
        </Panel>
      ),
    },
    {
      path: "workspaces/:workspaceId",
      element: (
        <Panel>
          <Workspace />
        </Panel>
      ),
    },
    {
      path: "events",
      element: (
        <Panel>
          <Events />
        </Panel>
      ),
      children: [
        {
          path: ":aggregateId",
          element: (
            <Panel>
              <Events />
            </Panel>
          ),
        },
      ],
    },
    {
      path: "document_chunks",
      element: (
        <Panel>
          <DocumentChunkList />
        </Panel>
      ),
    },
  ]);

  const onRedirectCallback = (appState: any) => {
    if (appState && appState.returnTo) {
      router.navigate(appState.returnTo);
    }
  };

  const queryClient = new QueryClient();

  root.render(
    <ErrorBoundary>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        screen_hint="signup"
      >
        <QueryClientProvider client={queryClient}>
          <TokenContextProvider>
            <ApiProvider>
              <RouterProvider router={router} />
            </ApiProvider>
          </TokenContextProvider>
        </QueryClientProvider>
      </Auth0Provider>
    </ErrorBoundary>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
export { POS } from "./pages/project/utils";
