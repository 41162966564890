/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddDimensionPatternMatching } from '../models/AddDimensionPatternMatching';
import type { ApplyTagPatch } from '../models/ApplyTagPatch';
import type { Body_add_document_project__project_id__documents_post } from '../models/Body_add_document_project__project_id__documents_post';
import type { Body_preview_project_preview_document__post } from '../models/Body_preview_project_preview_document__post';
import type { ChartType } from '../models/ChartType';
import type { ColorChart } from '../models/ColorChart';
import type { DimensionClassVerbatims } from '../models/DimensionClassVerbatims';
import type { DimensionId } from '../models/DimensionId';
import type { DocumentChunkInfo } from '../models/DocumentChunkInfo';
import type { DocumentView } from '../models/DocumentView';
import type { DuplicateTags } from '../models/DuplicateTags';
import type { FewShotTagging } from '../models/FewShotTagging';
import type { FewShotTaggingModelId } from '../models/FewShotTaggingModelId';
import type { ImportDocumentMetadata } from '../models/ImportDocumentMetadata';
import type { ImportFewShotTaggingModel } from '../models/ImportFewShotTaggingModel';
import type { JobMonitoringView } from '../models/JobMonitoringView';
import type { LexicalMatch } from '../models/LexicalMatch';
import type { MergeTokenTag } from '../models/MergeTokenTag';
import type { ModelPreset } from '../models/ModelPreset';
import type { NewProject } from '../models/NewProject';
import type { PatternMatchingManualAnnotationProjection } from '../models/PatternMatchingManualAnnotationProjection';
import type { PatternOccurrence } from '../models/PatternOccurrence';
import type { PatterOccurrenceWithTagsPaginated } from '../models/PatterOccurrenceWithTagsPaginated';
import type { ProjectAnalysisStats } from '../models/ProjectAnalysisStats';
import type { ProjectDimensionClasseDistribution } from '../models/ProjectDimensionClasseDistribution';
import type { ProjectDistributionStat } from '../models/ProjectDistributionStat';
import type { ProjectId } from '../models/ProjectId';
import type { ProjectView } from '../models/ProjectView';
import type { PutVerbatimTags } from '../models/PutVerbatimTags';
import type { RelauchDimensionProcess } from '../models/RelauchDimensionProcess';
import type { RelaunchDocumentChunkParsing } from '../models/RelaunchDocumentChunkParsing';
import type { RenameProject } from '../models/RenameProject';
import type { RenameProjectDimensionClass } from '../models/RenameProjectDimensionClass';
import type { TagAPattern } from '../models/TagAPattern';
import type { TagAVerbatim } from '../models/TagAVerbatim';
import type { TagAVerbatimToken } from '../models/TagAVerbatimToken';
import type { TagOccurencesWithRelatedTagsPaginated } from '../models/TagOccurencesWithRelatedTagsPaginated';
import type { TagUpdateScore } from '../models/TagUpdateScore';
import type { UnmergeTokenTag } from '../models/UnmergeTokenTag';
import type { UpdateAnnotation } from '../models/UpdateAnnotation';
import type { VerbatimPaginated } from '../models/VerbatimPaginated';
import type { VerbatimTagPatch } from '../models/VerbatimTagPatch';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProjectService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Model Presets
     * @returns ModelPreset Successful Response
     * @throws ApiError
     */
    public getModelPresetsProjectPresetsGet(): CancelablePromise<Array<ModelPreset>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/presets',
        });
    }

    /**
     * Get Project Patterns Csv
     * @param projectId
     * @param posPatterns
     * @param term
     * @returns PatternOccurrence Successful Response
     * @throws ApiError
     */
    public getProjectPatternsCsvProjectProjectIdPatternsCsvGet(
        projectId: string,
        posPatterns: string,
        term: string = '',
    ): CancelablePromise<Array<PatternOccurrence>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/patterns.csv',
            path: {
                'project_id': projectId,
            },
            query: {
                'pos_patterns': posPatterns,
                'term': term,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Project Patterns
     * @param projectId
     * @param posPatterns
     * @param term
     * @param minOccurrence
     * @param maxOccurrence
     * @param page
     * @param pageSize
     * @param searchWithParser
     * @param semanticSearch
     * @param semanticSearchThreshold
     * @returns PatterOccurrenceWithTagsPaginated Successful Response
     * @throws ApiError
     */
    public getProjectPatternsProjectProjectIdPatternsGet(
        projectId: string,
        posPatterns: string,
        term: string = '',
        minOccurrence?: number,
        maxOccurrence: number = 1000000,
        page: number = 1,
        pageSize: number = 50,
        searchWithParser: boolean = false,
        semanticSearch: boolean = false,
        semanticSearchThreshold: number = 0.5,
    ): CancelablePromise<PatterOccurrenceWithTagsPaginated> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/patterns',
            path: {
                'project_id': projectId,
            },
            query: {
                'pos_patterns': posPatterns,
                'term': term,
                'min_occurrence': minOccurrence,
                'max_occurrence': maxOccurrence,
                'page': page,
                'page_size': pageSize,
                'search_with_parser': searchWithParser,
                'semantic_search': semanticSearch,
                'semantic_search_threshold': semanticSearchThreshold,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Frequency Graph
     * @param projectId
     * @param dimensions
     * @param subset
     * @param nodes
     * @returns any Successful Response
     * @throws ApiError
     */
    public getFrequencyGraphProjectProjectIdFrequencyGraphGet(
        projectId: string,
        dimensions: string = 'keywords',
        subset?: string,
        nodes: number = 80,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/frequency_graph',
            path: {
                'project_id': projectId,
            },
            query: {
                'dimensions': dimensions,
                'subset': subset,
                'nodes': nodes,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Run Preset
     * @param projectId
     * @param presetId
     * @returns any Successful Response
     * @throws ApiError
     */
    public runPresetProjectProjectIdRunPresetPresetIdPost(
        projectId: string,
        presetId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/run_preset/{preset_id}',
            path: {
                'project_id': projectId,
                'preset_id': presetId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Pattern Matching Dimension
     * @param projectId
     * @param requestBody
     * @returns DimensionId Successful Response
     * @throws ApiError
     */
    public addPatternMatchingDimensionProjectProjectIdAddPatternMatchingDimensionPost(
        projectId: string,
        requestBody: AddDimensionPatternMatching,
    ): CancelablePromise<DimensionId> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/add_pattern_matching_dimension',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Rename Project Dimension Class
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public renameProjectDimensionClassProjectProjectIdDimensionDimensionClassClassnameRenamePost(
        requestBody: RenameProjectDimensionClass,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/dimension/{dimension}/class/{classname}/rename',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reset Dimension With Model
     * @param projectId
     * @param dimensionId
     * @returns any Successful Response
     * @throws ApiError
     */
    public resetDimensionWithModelProjectProjectIdResetDimensionDimensionIdPost(
        projectId: string,
        dimensionId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/reset_dimension/{dimension_id}',
            path: {
                'project_id': projectId,
                'dimension_id': dimensionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Dimension
     * @param projectId
     * @param dimensionId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteDimensionProjectProjectIdDimensionsDimensionsIdDelete(
        projectId: string,
        dimensionId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/project/{project_id}/dimensions/{dimensions_id}',
            path: {
                'project_id': projectId,
            },
            query: {
                'dimension_id': dimensionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Autocomplete Dimension With Fewshoot Learning Model
     * @param requestBody
     * @returns FewShotTaggingModelId Successful Response
     * @throws ApiError
     */
    public autocompleteDimensionWithFewshootLearningModelProjectProjectIdDimensionsDimensionIdFewshotTaggingPost(
        requestBody: FewShotTagging,
    ): CancelablePromise<FewShotTaggingModelId> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/dimensions/{dimension_id}/fewshot_tagging',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Dimension Model
     * @param projectId
     * @param dimensionId
     * @returns FewShotTaggingModelId Successful Response
     * @throws ApiError
     */
    public updateDimensionModelProjectProjectIdDimensionsDimensionIdFewShotLearningModelUpdatePost(
        projectId: string,
        dimensionId: string,
    ): CancelablePromise<FewShotTaggingModelId> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/dimensions/{dimension_id}/few_shot_learning_model/update',
            path: {
                'project_id': projectId,
                'dimension_id': dimensionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Import Few Shot Tagging Model
     * @param requestBody
     * @returns DimensionId Successful Response
     * @throws ApiError
     */
    public importFewShotTaggingModelProjectProjectIdImportFewshotTaggingModelPost(
        requestBody: ImportFewShotTaggingModel,
    ): CancelablePromise<DimensionId> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/import_fewshot_tagging_model',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Project Stats
     * @param projectId
     * @returns ProjectDistributionStat Successful Response
     * @throws ApiError
     */
    public getProjectStatsProjectProjectIdStatsTokenClassificationGet(
        projectId: string,
    ): CancelablePromise<Array<ProjectDistributionStat>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/stats/token_classification',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Mannual Annotation
     * @param annotationId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateMannualAnnotationProjectManualAnnotationsAnnotationIdPut(
        annotationId: string,
        requestBody: UpdateAnnotation,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/project/manual_annotations/{annotation_id}',
            path: {
                'annotation_id': annotationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Project Manual Annotation
     * @param projectId
     * @returns PatternMatchingManualAnnotationProjection Successful Response
     * @throws ApiError
     */
    public getProjectManualAnnotationProjectProjectIdManualAnnotationsGet(
        projectId: string,
    ): CancelablePromise<Array<PatternMatchingManualAnnotationProjection>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/manual_annotations',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Project
     * @returns ProjectView Successful Response
     * @throws ApiError
     */
    public getProjectProjectGet(): CancelablePromise<Array<ProjectView>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/',
        });
    }

    /**
     * New Project
     * Create a new project
     * @param requestBody
     * @returns ProjectId Successful Response
     * @throws ApiError
     */
    public newProjectProjectPost(
        requestBody: NewProject,
    ): CancelablePromise<ProjectId> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Rename Project
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public renameProjectProjectProjectIdRenamePost(
        requestBody: RenameProject,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/rename',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Preview
     * @param formData
     * @returns string Successful Response
     * @throws ApiError
     */
    public previewProjectPreviewDocumentPost(
        formData: Body_preview_project_preview_document__post,
    ): CancelablePromise<Array<Array<string>>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/preview_document/',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Documents
     * @param projectId
     * @returns DocumentView Successful Response
     * @throws ApiError
     */
    public getDocumentsProjectProjectIdDocumentsGet(
        projectId: string,
    ): CancelablePromise<Array<DocumentView>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/documents',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Document
     * @param projectId
     * @param formData
     * @param ignoreHeader
     * @param dataColumn
     * @param splitCell
     * @returns any Successful Response
     * @throws ApiError
     */
    public addDocumentProjectProjectIdDocumentsPost(
        projectId: string,
        formData: Body_add_document_project__project_id__documents_post,
        ignoreHeader: boolean = true,
        dataColumn?: number,
        splitCell: boolean = true,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/documents',
            path: {
                'project_id': projectId,
            },
            query: {
                'ignore_header': ignoreHeader,
                'data_column': dataColumn,
                'split_cell': splitCell,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Project Info
     * @param projectId
     * @returns ProjectView Successful Response
     * @throws ApiError
     */
    public getProjectInfoProjectProjectIdGet(
        projectId: string,
    ): CancelablePromise<ProjectView> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Project
     * Delete a project
     * @param projectId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteProjectProjectProjectIdDelete(
        projectId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/project/{project_id}',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Relaunch Document Chunk Parsing
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public relaunchDocumentChunkParsingProjectProjectIdRelaunchDocumentChunkParsingPost(
        requestBody: RelaunchDocumentChunkParsing,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/relaunch_document_chunk_parsing',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Relaunch Dimension Process
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public relaunchDimensionProcessProjectProjectIdRelaunchDimensionProcessPost(
        requestBody: RelauchDimensionProcess,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/relaunch_dimension_process',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Document Chunks
     * @param projectId
     * @returns DocumentChunkInfo Successful Response
     * @throws ApiError
     */
    public getDocumentChunksProjectDocumentChunksProjectIdGet(
        projectId: string,
    ): CancelablePromise<Array<DocumentChunkInfo>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/document_chunks/{project_id}',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Document
     * @param projectId
     * @param documentId
     * @param dimensions
     * @returns any Successful Response
     * @throws ApiError
     */
    public getDocumentProjectProjectIdDocumentsDocumentIdGet(
        projectId: string,
        documentId: string,
        dimensions: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/documents/{document_id}',
            path: {
                'project_id': projectId,
                'document_id': documentId,
            },
            query: {
                'dimensions': dimensions,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Import Document Metadata
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public importDocumentMetadataProjectProjectIdDocumentsDocumentIdImportMetadataPost(
        requestBody: ImportDocumentMetadata,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/documents/{document_id}/import_metadata',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Project Jobs
     * @param projectId
     * @returns JobMonitoringView Successful Response
     * @throws ApiError
     */
    public getProjectJobsProjectProjectIdJobsGet(
        projectId: string,
    ): CancelablePromise<Array<JobMonitoringView>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/jobs',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Project History
     * @param projectId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getProjectHistoryProjectProjectIdHistoryGet(
        projectId: string,
    ): CancelablePromise<Array<any>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/history',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Parsing Status
     * @param projectId
     * @returns ProjectAnalysisStats Successful Response
     * @throws ApiError
     */
    public getParsingStatusProjectProjectIdParsingStatusGet(
        projectId: string,
    ): CancelablePromise<ProjectAnalysisStats> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/parsing_status',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Parsing Dataviz
     * @param projectId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getParsingDatavizProjectProjectIdParsingDatavizHtmlGet(
        projectId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/parsing_dataviz.html',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Multidimensionnal Plot Html
     * @param projectId
     * @param type
     * @param parents
     * @param childs
     * @param maxClassParents
     * @param maxClassChilds
     * @param color
     * @returns any Successful Response
     * @throws ApiError
     */
    public getMultidimensionnalPlotHtmlProjectProjectIdPlotTypeHtmlGet(
        projectId: string,
        type: ChartType,
        parents: string,
        childs: string,
        maxClassParents: number,
        maxClassChilds: number,
        color?: ColorChart,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/plot/{type}.html',
            path: {
                'project_id': projectId,
                'type': type,
            },
            query: {
                'parents': parents,
                'childs': childs,
                'maxClassParents': maxClassParents,
                'maxClassChilds': maxClassChilds,
                'color': color,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Dimension Matches Distribution Csv
     * @param projectId
     * @param dimension
     * @returns any Successful Response
     * @throws ApiError
     */
    public getDimensionMatchesDistributionCsvProjectProjectIdStatsDimensionCsvGet(
        projectId: string,
        dimension: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/stats/{dimension}.csv',
            path: {
                'project_id': projectId,
                'dimension': dimension,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Dimension Tags Csv
     * @param projectId
     * @param dimension
     * @returns any Successful Response
     * @throws ApiError
     */
    public getDimensionTagsCsvProjectProjectIdStatsDimensionTagsCsvGet(
        projectId: string,
        dimension: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/stats/{dimension}/tags.csv',
            path: {
                'project_id': projectId,
                'dimension': dimension,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Dimension Matches Distribution
     * @param projectId
     * @param dimension
     * @returns ProjectDimensionClasseDistribution Successful Response
     * @throws ApiError
     */
    public getDimensionMatchesDistributionProjectProjectIdStatsDimensionGet(
        projectId: string,
        dimension: string,
    ): CancelablePromise<Array<ProjectDimensionClasseDistribution>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/stats/{dimension}',
            path: {
                'project_id': projectId,
                'dimension': dimension,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Dimension Worldcloud
     * @param projectId
     * @param dimension
     * @param classCount
     * @param wordsCount
     * @param width
     * @param height
     * @param defaultColor
     * @param fontSizeDisparity
     * @param fontMin
     * @returns any Successful Response
     * @throws ApiError
     */
    public getDimensionWorldcloudProjectProjectIdStatsDimensionWordcloudSvgGet(
        projectId: string,
        dimension: string,
        classCount: number = 5,
        wordsCount: number = 20,
        width: number = 1200,
        height: number = 420,
        defaultColor: string = 'indigo',
        fontSizeDisparity: number = 0.5,
        fontMin: number = 10,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/stats/{dimension}/wordcloud.svg',
            path: {
                'project_id': projectId,
                'dimension': dimension,
            },
            query: {
                'class_count': classCount,
                'words_count': wordsCount,
                'width': width,
                'height': height,
                'default_color': defaultColor,
                'font_size_disparity': fontSizeDisparity,
                'font_min': fontMin,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Project Stat Csv
     * @param projectId
     * @param dimension
     * @returns any Successful Response
     * @throws ApiError
     */
    public getProjectStatCsvProjectProjectIdStatsDimensionDistributionCsvGet(
        projectId: string,
        dimension: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/stats/{dimension}/distribution.csv',
            path: {
                'project_id': projectId,
                'dimension': dimension,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Project Stat Html
     * @param projectId
     * @param dimension
     * @param type
     * @param color
     * @param nbCategories
     * @returns any Successful Response
     * @throws ApiError
     */
    public getProjectStatHtmlProjectProjectIdStatsDimensionPlotTypeHtmlGet(
        projectId: string,
        dimension: string,
        type: ChartType,
        color: string = 'indigo',
        nbCategories: number = 5,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/stats/{dimension}/plot_{type}.html',
            path: {
                'project_id': projectId,
                'dimension': dimension,
                'type': type,
            },
            query: {
                'color': color,
                'nbCategories': nbCategories,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Verbatims Groupby Dimension
     * @param projectId
     * @param dimension
     * @returns DimensionClassVerbatims Successful Response
     * @throws ApiError
     */
    public getVerbatimsGroupbyDimensionProjectVerbatimsProjectIdGroupbyDimensionGet(
        projectId: string,
        dimension: string,
    ): CancelablePromise<Array<DimensionClassVerbatims>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/verbatims/{project_id}/groupby/{dimension}',
            path: {
                'project_id': projectId,
                'dimension': dimension,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Verbatims Train Data
     * @param projectId
     * @param dimension
     * @returns any Successful Response
     * @throws ApiError
     */
    public getVerbatimsTrainDataProjectVerbatimsProjectIdTrainDataJsonlGet(
        projectId: string,
        dimension: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/verbatims/{project_id}/train_data.jsonl',
            path: {
                'project_id': projectId,
            },
            query: {
                'dimension': dimension,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Verbatims Csv
     * @param projectId
     * @param lemmas
     * @param dimension
     * @param classname
     * @param subset
     * @param frequencyGraphDimensions
     * @param frequencyGraphNodes
     * @param sortByClassScore
     * @returns any Successful Response
     * @throws ApiError
     */
    public getVerbatimsCsvProjectVerbatimsProjectIdCsvGet(
        projectId: string,
        lemmas: string = '',
        dimension: string = '',
        classname: string = '',
        subset: string = '',
        frequencyGraphDimensions: string = '',
        frequencyGraphNodes: string = '',
        sortByClassScore: string = '',
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/verbatims/{project_id}.csv',
            path: {
                'project_id': projectId,
            },
            query: {
                'lemmas': lemmas,
                'dimension': dimension,
                'classname': classname,
                'subset': subset,
                'frequency_graph_dimensions': frequencyGraphDimensions,
                'frequency_graph_nodes': frequencyGraphNodes,
                'sort_by_class_score': sortByClassScore,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Verbatims
     * @param projectId
     * @param lemmas
     * @param dimension
     * @param classname
     * @param subset
     * @param frequencyGraphDimensions
     * @param frequencyGraphNodes
     * @param sortByClassScore
     * @param randomOrder
     * @param nonVerifiedDimension
     * @param p
     * @returns VerbatimPaginated Successful Response
     * @throws ApiError
     */
    public getVerbatimsProjectVerbatimsProjectIdGet(
        projectId: string,
        lemmas: string = '',
        dimension: string = '',
        classname: string = '',
        subset: string = '',
        frequencyGraphDimensions: string = '',
        frequencyGraphNodes: string = '',
        sortByClassScore: string = '',
        randomOrder: boolean = false,
        nonVerifiedDimension: string = '',
        p: number = 1,
    ): CancelablePromise<VerbatimPaginated> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/verbatims/{project_id}',
            path: {
                'project_id': projectId,
            },
            query: {
                'lemmas': lemmas,
                'dimension': dimension,
                'classname': classname,
                'subset': subset,
                'frequency_graph_dimensions': frequencyGraphDimensions,
                'frequency_graph_nodes': frequencyGraphNodes,
                'sort_by_class_score': sortByClassScore,
                'random_order': randomOrder,
                'non_verified_dimension': nonVerifiedDimension,
                'p': p,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Lexical Similarity
     * @param projectId
     * @param threshold
     * @param requestBody
     * @returns LexicalMatch Successful Response
     * @throws ApiError
     */
    public getLexicalSimilarityProjectProjectIdSearchLexicalSimilarityPost(
        projectId: string,
        threshold: number,
        requestBody: Array<string>,
    ): CancelablePromise<Array<LexicalMatch>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/search_lexical_similarity',
            path: {
                'project_id': projectId,
            },
            query: {
                'threshold': threshold,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Tags By Tag
     * @param projectId
     * @param dimension
     * @param page
     * @param pageSize
     * @returns TagOccurencesWithRelatedTagsPaginated Successful Response
     * @throws ApiError
     */
    public searchTagsByTagProjectProjectIdSearchTagsGet(
        projectId: string,
        dimension: string,
        page: number = 1,
        pageSize: number = 50,
    ): CancelablePromise<TagOccurencesWithRelatedTagsPaginated> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/search_tags',
            path: {
                'project_id': projectId,
            },
            query: {
                'dimension': dimension,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tag A Pattern
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public tagAPatternProjectProjectIdTagPatternPost(
        requestBody: TagAPattern,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/tag_pattern',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tag A Verbatim Token
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public tagAVerbatimTokenProjectProjectIdTagTokenPost(
        requestBody: TagAVerbatimToken,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/tag_token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tag A Verbatim
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public tagAVerbatimProjectProjectIdTagVerbatimPost(
        requestBody: TagAVerbatim,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/tag_verbatim',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Put Verbatim Tags
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public putVerbatimTagsProjectProjectIdVerbatimVerbatimIdTagsPut(
        requestBody: PutVerbatimTags,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/project/{project_id}/verbatim/{verbatim_id}/tags/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Duplicate Tags
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public duplicateTagsProjectProjectIdDuplicateTagsPost(
        requestBody: DuplicateTags,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/duplicate_tags',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Tag
     * @param projectId
     * @param tagId
     * @param className
     * @returns any Successful Response
     * @throws ApiError
     */
    public editTagProjectProjectIdTagTagIdPost(
        projectId: string,
        tagId: string,
        className: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/tag/{tag_id}',
            path: {
                'project_id': projectId,
                'tag_id': tagId,
            },
            query: {
                'class_name': className,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Tag
     * @param projectId
     * @param tagId
     * @param allOccurrences
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteTagProjectProjectIdTagTagIdDelete(
        projectId: string,
        tagId: string,
        allOccurrences: boolean = false,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/project/{project_id}/tag/{tag_id}',
            path: {
                'project_id': projectId,
                'tag_id': tagId,
            },
            query: {
                'all_occurrences': allOccurrences,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Tag By Class
     * @param projectId
     * @param classname
     * @param dimension
     * @param name
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteTagByClassProjectProjectIdTagDelete(
        projectId: string,
        classname: string,
        dimension: string,
        name: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/project/{project_id}/tag/',
            path: {
                'project_id': projectId,
            },
            query: {
                'classname': classname,
                'dimension': dimension,
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Merge Token Tag
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public mergeTokenTagProjectProjectIdMergeTokenTagPost(
        requestBody: MergeTokenTag,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/merge_token_tag',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unmerge Token Tags
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public unmergeTokenTagsProjectProjectIdUnmergeTokenTagPost(
        requestBody: UnmergeTokenTag,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/unmerge_token_tag',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tag Update Score
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public tagUpdateScoreProjectTagsTagIdPost(
        requestBody: TagUpdateScore,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/tags/{tag_id}',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Apply Tag Patch
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public applyTagPatchProjectProjectIdApplyTagPatchPost(
        requestBody: ApplyTagPatch,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/project/{project_id}/apply_tag_patch',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Tag Patch
     * @param projectId
     * @param dimension
     * @returns VerbatimTagPatch Successful Response
     * @throws ApiError
     */
    public getTagPatchProjectProjectIdTagPatchGet(
        projectId: string,
        dimension: string,
    ): CancelablePromise<Array<VerbatimTagPatch>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project/{project_id}/tag_patch',
            path: {
                'project_id': projectId,
            },
            query: {
                'dimension': dimension,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
