import React, { useContext, useEffect, useState } from "react";
import { ModelType, WorkspaceModel } from "../../../app_client";
import { useNavigate } from "react-router-dom";
import { YMButton, YMCard, YMTable } from "../../../ym-components";
import { Spinner, Tabs } from "flowbite-react";
import { SiDatabricks } from "react-icons/si";
import { useProject, useProjectApi } from "../State";
import {
  notificationDismiss,
  notificationInfo,
} from "../../../toast-notification";
import { AuthUserContext } from "../../../AuthUserContext";
import { tabStyle } from "../../../style";

type importableModel =
  | WorkspaceModel
  | { model_id: string; name: string; type: "PRESET" };

export default function ImportModels(props: any) {
  const { projectId, _ProjectAnalysisStats } = useProject();
  const projectApi = useProjectApi();
  const { workspaceId } = useContext(AuthUserContext) as {
    workspaceId: string;
  };
  const [models, setModels] = useState<importableModel[] | "loading">("loading");
  const navigate = useNavigate();

  // Fetch models on component mount
  useEffect(() => {
    if (projectApi && workspaceId) {
      projectApi.getWorkspaceModels(workspaceId).then(setModels);
    }
  }, [projectApi, workspaceId]);

  const handleImport = async (model: importableModel) => {
    if (projectApi && projectId) {
      if (model.type === ModelType.PATTERN_MATCHING)
        await notifyAndImportModel(model, projectApi.importPatternMatchingModel);
      if (model.type === ModelType.FEWSHOOT_TAGGING)
        await notifyAndImportModel(model, projectApi.importFewShotTaggingModel);
      if (model.type === "PRESET")
        await notifyAndImportModel(model, projectApi.importPresetModel);
    }
  };

  const notifyAndImportModel = async (model: importableModel, call: (projectId: string, modelId: string, modelName: string) => Promise<any>) => {
    if (projectApi && projectId) {
      let notificationid = notificationInfo(
        `L'analyse du project avec le modèle ${model.name} va bientôt démarrer..`,
        { duration: 10000 }
      );
      await call(
        projectId,
        model.model_id,
        model.name
      );
      notificationDismiss(notificationid);
      notificationInfo(
        `L'analyse du project avec le modèle ${model.name} est en cours.`
      );
      setTimeout(() => {
        navigate("/project/" + projectId + "/");
        _ProjectAnalysisStats.refetch();
      }, 1000);
    }
  };
  
  return (
    <div>
      <YMCard>
        <div className="flex flex-col w-100">
          <div className="format">
            <h2 className="text-indigo-900">
              <SiDatabricks className="inline" /> Modèles
            </h2>
            <p className="-mt-5 text-sm font-normal text-indigo-900 dark:text-gray-400">
              Ajouter une description ici ....
            </p>
          </div>
        </div>
      </YMCard>
      {models === "loading" ? (
        <div className="w-100 mt-10 text-center">
          <Spinner color="gray" size="xl" aria-label="Default status example" />
          <div className="mt-4 text-gray-400">fetching models</div>
        </div>
      ) : models.length === 0 ? (
        <div className="w-100 mt-5 text-center">
          <p className="text-gray-500 mb-4">No models available.</p>
        </div>
      ) : (
        <div className="relative overflow-x-auto rounded-lg border border-gray-200 bg-white shadow-lg mt-5">
          <Tabs aria-label="Default tabs" theme={tabStyle}>
            <Tabs.Item active title="Modèles Pattern Matching">
              <ModelTable
                models={models.filter(
                  (m) => m.type === ModelType.PATTERN_MATCHING
                )}
                onImport={handleImport}
              />
            </Tabs.Item>
            <Tabs.Item active title="Modèles FST">
              <ModelTable
                models={models.filter(
                  (m) => m.type === ModelType.FEWSHOOT_TAGGING
                )}
                onImport={handleImport}
              />
            </Tabs.Item>
            <Tabs.Item active title="Autres Modèles">
              <ModelTable
                models={[
                  {
                    model_id: "polarity_1",
                    name: "Sentiments",
                    type: "PRESET",
                  },
                  {
                    model_id: "jugement_1",
                    name: "Jugement",
                    type: "PRESET",
                  },
                    {
                    model_id: "aspiration_1",
                    name: "Aspirations",
                    type: "PRESET",
                  },
                    {
                    model_id: "intensité_1",
                    name: "Intensité",
                    type: "PRESET",
                  },
                    {
                    model_id: "ner_loc_gliner_1",
                    name: "GliNER",
                    type: "PRESET",
                  }
                ]}
                onImport={handleImport}
              />
            </Tabs.Item>
          </Tabs>
        </div>
      )}
    </div>
  );
}


const ModelTable = ({ models, onImport }: {
  models: importableModel[];
    onImport: (model: importableModel) => void;
}) => {

   const { projectId } = useProject();
   const navigate = useNavigate();
  const handleOnClickModel = (modelId: string, modelName: string) => {
    navigate(
      (projectId ? `/project/${projectId}` : "") +
        `/pattern_matching_models/edit?id=${modelId}&name=${modelName}`
    );
  };

  if (models.length === 0) return <div className={"text-gray-500 pl-4 pt-2"}>
    Aucun modèle disponible
  </div>;


  return  <YMTable
            header={
              <tr>
                <th scope="col" className="px-6 py-3">
                  Models
                </th>
                <th scope="col" className="px-6 py-3">
                  <span className="sr-only"> x </span>
                </th>
              </tr>
            }
            rows={models.map((m) => (
              <tr
                key={m.model_id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100"
              >
                <td
                  scope="row"
                  className="px-6 py-4 text-indigo-900 cursor-pointer capitalize font-bold max-w-[48px] hover:text-indigo-900 dark:text-white"
                  onClick={() => handleOnClickModel(m.model_id, m.name)}
                >
                  <div className="flex flex-row space-x-4">
                    <div>{m.name} </div>
                  </div>
                </td>
                <td className="px-6 py-4 flex gap-2">
                    <YMButton
                      size="xs"
                      text={"importer"}
                      onClick={() => onImport(m)}
                    />
                </td>
              </tr>
            ))}
          />
};