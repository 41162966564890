/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { AppCLient } from './AppCLient';

export { ApiError } from './core/ApiError';
export { BaseHttpRequest } from './core/BaseHttpRequest';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { AddDimensionPatternMatching } from './models/AddDimensionPatternMatching';
export { AnnotationLearningScope } from './models/AnnotationLearningScope';
export { AnnotationType } from './models/AnnotationType';
export type { ApplyTagPatch } from './models/ApplyTagPatch';
export type { BertopicParams } from './models/BertopicParams';
export type { Body_add_document_project__project_id__documents_post } from './models/Body_add_document_project__project_id__documents_post';
export type { Body_preview_project_preview_document__post } from './models/Body_preview_project_preview_document__post';
export { ChartType } from './models/ChartType';
export { ClassificationMethod } from './models/ClassificationMethod';
export { ClassificationModelType } from './models/ClassificationModelType';
export { ClusteringAlgo } from './models/ClusteringAlgo';
export type { ClusteringParams } from './models/ClusteringParams';
export { ColorChart } from './models/ColorChart';
export { ColorName } from './models/ColorName';
export type { CreateFewShotTaggingModelFromProjectTags } from './models/CreateFewShotTaggingModelFromProjectTags';
export type { CreateNewPatternMatchingModel } from './models/CreateNewPatternMatchingModel';
export type { CreateOrGetWorkspace } from './models/CreateOrGetWorkspace';
export type { DimensionClassVerbatims } from './models/DimensionClassVerbatims';
export type { DimensionId } from './models/DimensionId';
export type { DimensionParsingStats } from './models/DimensionParsingStats';
export type { DimensionProcessInfo } from './models/DimensionProcessInfo';
export { DimRec } from './models/DimRec';
export type { DimRecParams } from './models/DimRecParams';
export type { DocumentChunkInfo } from './models/DocumentChunkInfo';
export type { DocumentParams } from './models/DocumentParams';
export { DocumentState } from './models/DocumentState';
export type { DocumentView } from './models/DocumentView';
export { domains__job_management__values__JobStatus } from './models/domains__job_management__values__JobStatus';
export { domains__topic_modeling__topic_modeling__JobStatus } from './models/domains__topic_modeling__topic_modeling__JobStatus';
export type { DuplicateTags } from './models/DuplicateTags';
export type { EmbedJobId } from './models/EmbedJobId';
export type { EmbedSentences } from './models/EmbedSentences';
export type { FewShotTagging } from './models/FewShotTagging';
export type { FewShotTaggingModelId } from './models/FewShotTaggingModelId';
export { FewShotTaggingModelStatus } from './models/FewShotTaggingModelStatus';
export type { FewShotTaggingModelView } from './models/FewShotTaggingModelView';
export type { HTTPValidationError } from './models/HTTPValidationError';
export type { IdentityResponse } from './models/IdentityResponse';
export type { ImportDocumentMetadata } from './models/ImportDocumentMetadata';
export type { ImportFewShotTaggingModel } from './models/ImportFewShotTaggingModel';
export type { JobId } from './models/JobId';
export type { JobMonitoringView } from './models/JobMonitoringView';
export { JobType } from './models/JobType';
export type { JoinWorkspaceRequest } from './models/JoinWorkspaceRequest';
export { Language } from './models/Language';
export type { LexicalMatch } from './models/LexicalMatch';
export type { MergeTokenTag } from './models/MergeTokenTag';
export type { ModelId } from './models/ModelId';
export type { ModelPreset } from './models/ModelPreset';
export { ModelType } from './models/ModelType';
export type { NewJob } from './models/NewJob';
export type { NewProject } from './models/NewProject';
export type { NewTopicModelingJob } from './models/NewTopicModelingJob';
export type { NewWorkspaceFewShotTaggingModels } from './models/NewWorkspaceFewShotTaggingModels';
export type { NewWorkspaceModel } from './models/NewWorkspaceModel';
export type { NewWorkspaceProject } from './models/NewWorkspaceProject';
export type { PatternMatchingManualAnnotationProjection } from './models/PatternMatchingManualAnnotationProjection';
export type { PatternMatchingModelProjection } from './models/PatternMatchingModelProjection';
export type { PatternMatchingRule } from './models/PatternMatchingRule';
export type { PatternOccurrence } from './models/PatternOccurrence';
export type { PatterOccurrenceWithTags } from './models/PatterOccurrenceWithTags';
export type { PatterOccurrenceWithTagsPaginated } from './models/PatterOccurrenceWithTagsPaginated';
export { Permissions } from './models/Permissions';
export { POS } from './models/POS';
export type { ProgressionStats } from './models/ProgressionStats';
export type { ProjectAnalysisStats } from './models/ProjectAnalysisStats';
export type { ProjectDimension } from './models/ProjectDimension';
export type { ProjectDimensionClasseDistribution } from './models/ProjectDimensionClasseDistribution';
export { ProjectDimensionStatus } from './models/ProjectDimensionStatus';
export type { ProjectDistributionStat } from './models/ProjectDistributionStat';
export type { ProjectId } from './models/ProjectId';
export type { ProjectStatistics } from './models/ProjectStatistics';
export type { ProjectView } from './models/ProjectView';
export type { PutVerbatimTags } from './models/PutVerbatimTags';
export type { RelauchDimensionProcess } from './models/RelauchDimensionProcess';
export type { RelaunchDocumentChunkParsing } from './models/RelaunchDocumentChunkParsing';
export type { RenameProject } from './models/RenameProject';
export type { RenameProjectDimensionClass } from './models/RenameProjectDimensionClass';
export type { TagAPattern } from './models/TagAPattern';
export type { TagAVerbatim } from './models/TagAVerbatim';
export type { TagAVerbatimToken } from './models/TagAVerbatimToken';
export type { TaggedToken } from './models/TaggedToken';
export type { TagOccurencesWithRelatedTags } from './models/TagOccurencesWithRelatedTags';
export type { TagOccurencesWithRelatedTagsPaginated } from './models/TagOccurencesWithRelatedTagsPaginated';
export { TagType } from './models/TagType';
export type { TagUpdateScore } from './models/TagUpdateScore';
export type { Topic } from './models/Topic';
export type { TopicModelingJobView } from './models/TopicModelingJobView';
export { TopicModelingVisualizationType } from './models/TopicModelingVisualizationType';
export type { UnmergeTokenTag } from './models/UnmergeTokenTag';
export type { UpdateAnnotation } from './models/UpdateAnnotation';
export type { UpdatePatternMatchingModel } from './models/UpdatePatternMatchingModel';
export type { UpdateStopWords } from './models/UpdateStopWords';
export type { ValidationError } from './models/ValidationError';
export type { VectorizerParams } from './models/VectorizerParams';
export type { VerbatimPaginated } from './models/VerbatimPaginated';
export type { VerbatimTag } from './models/VerbatimTag';
export type { VerbatimTagPatch } from './models/VerbatimTagPatch';
export type { VerbatimViewWithTagTokens } from './models/VerbatimViewWithTagTokens';
export type { WorkspaceAddCreditToken } from './models/WorkspaceAddCreditToken';
export type { WorkspaceGuestUser } from './models/WorkspaceGuestUser';
export type { WorkspaceIdResponse } from './models/WorkspaceIdResponse';
export type { WorkspaceModel } from './models/WorkspaceModel';
export type { WorkspaceProject } from './models/WorkspaceProject';
export type { WorkspaceTokenMove } from './models/WorkspaceTokenMove';
export { WorkspaceTokenMoveReason } from './models/WorkspaceTokenMoveReason';
export type { WorkspaceView } from './models/WorkspaceView';

export { EmbeddingService } from './services/EmbeddingService';
export { FewShotTaggingModelService } from './services/FewShotTaggingModelService';
export { KernelEndpointService } from './services/KernelEndpointService';
export { NlpJobsService } from './services/NlpJobsService';
export { PatternMatchingModelService } from './services/PatternMatchingModelService';
export { ProjectService } from './services/ProjectService';
export { RateLimitInfoService } from './services/RateLimitInfoService';
export { TopicModelingJobService } from './services/TopicModelingJobService';
export { UserInfoService } from './services/UserInfoService';
export { WorkspaceService } from './services/WorkspaceService';
