import { useParams, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  AnnotationLearningScope,
  AppCLient,
  ClassificationMethod,
  ColorName,
  DimensionClassVerbatims,
  DimensionId,
  DimensionParsingStats,
  DocumentView,
  DuplicateTags,
  FewShotTaggingModelId,
  FewShotTaggingModelView,
  Language,
  LexicalMatch,
  ModelPreset,
  PatternMatchingManualAnnotationProjection,
  PatterOccurrenceWithTagsPaginated,
  ProjectAnalysisStats,
  ProjectDimension,
  ProjectView,
  TagAPattern,
  TagOccurencesWithRelatedTagsPaginated,
  VerbatimTagPatch,
  WorkspaceModel,
} from "../../app_client";
import useSyncMutation, { formatNumber } from "../../util";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { POS } from "./utils";
import {
  LexiquePatterns,
  NgrammesPatterns,
  prefecthPatterns,
} from "./lexicon/State";
import { notificationInfo } from "../../toast-notification";
import { AuthUserContext } from "../../AuthUserContext";

export interface ProjectApi {
  previewDocument: (file: File) => Promise<string[][]>;

  getProjects: () => Promise<any>;

  updateProject(projectId: string, data: { newName: string }): Promise<void>;

  deleteProject: (projectId: string) => Promise<void>;
  createProject: (name: string, lang: Language) => Promise<string>;
  addFileToProject: (
    projectId: string,
    file: File,
    params: any
  ) => Promise<void>;
  getProject: (projectId: string) => Promise<ProjectView>;
  getProjectTags: (
    project_id: string,
    page: number,
    dimension: string
  ) => Promise<TagOccurencesWithRelatedTagsPaginated>;
  getProjectPatterns: (
    project_id: string,
    pos_patterns: POS[][] | undefined,
    term: string | undefined,
    min_occurrences: number,
    max_occurrences: number,
    page: number,
    enabledParsing: boolean,
    semanticSearch: boolean
  ) => Promise<PatterOccurrenceWithTagsPaginated>;
  getPresets: () => Promise<ModelPreset[]>;
  triggerPreset: (projectId: string, presetId: string) => Promise<void>;
  getHistory: (projectId: string) => Promise<any[]>;
  getProjectParsingAnalyseUrl: (projectId: string) => string;

  getDocuments: (projectId: string) => Promise<DocumentView[]>;
  deleteDocument: (documentId: string) => Promise<void>;
  exportDocument: (
    document: DocumentView,
    dimensions: String[]
  ) => Promise<void>;
  importMetadata: (document: DocumentView, metadata: string[]) => Promise<void>;
  editTag: (
    projectId: string,
    tagId: string,
    className: string
  ) => Promise<void>;
  deleteVerbatimTag: (
    projectId: string,
    tagId: string,
    allOccurrence?: boolean
  ) => Promise<void>;
  addAVerbatimTag: (
    projectId: string,
    verbatimId: string,
    dimension: string,
    className: string
  ) => Promise<void>;
  putVerbatimTags: (
    projectId: string,
    verbatimId: string,
    dimension: string,
    tags: string[]
  ) => Promise<void>;
  tagAPattern: (params: TagAPattern) => Promise<void>;
  getTagPatches: (
    projectId: string,
    dimension: string
  ) => Promise<VerbatimTagPatch[]>;
  applyTagPatch: (projectId: string, patchIds: string[]) => Promise<void>;
  getWorkspaceModels: (workspaceId: string) => Promise<WorkspaceModel[]>;
  importPatternMatchingModel: (
    projectId: string,
    modelId: string,
    modelName: string
  ) => Promise<DimensionId>;
  importFewShotTaggingModel: (
    projectId: string,
    modelId: string,
    modelName: string
  ) => Promise<DimensionId>;
  importPresetModel: (
    projectId: string,
    modelId: string,
    modelName: string
  ) => Promise<DimensionId>;
  createPatternMatchingDimension: (
    projectId: string,
    modelName: string,
    className: string
  ) => Promise<DimensionId>;

  getManualCorrection: (projectId: string) => Promise<any>;
  updateManualCorrection: (
    annotationId: string,
    learningScope: AnnotationLearningScope
  ) => Promise<any>;

  resetDimension: (
    projectId: string,
    dimensionId: string
  ) => Promise<PatternMatchingManualAnnotationProjection[]>;
  deleteDimension: (projectId: string, dimensionId: string) => Promise<void>;

  getMultiDimensionalPlotIframeUrl: (projectId: string, type: string) => string;
  getBertopicBartChart: (analyseId: string, type: string) => string;
  renameTopic: (
    projectId: string,
    className: string,
    newClassName: string
  ) => Promise<void>;
  duplicateATag: (params: DuplicateTags) => Promise<void>;
  getTopicVerbatims: (projectId: string) => Promise<DimensionClassVerbatims[]>;
  getFrequencyGraph: (
    projectId: string,
    dimensions: string,
    subset: string,
    nodes: number
  ) => Promise<any>;
  getProjectParsingStats: (projectId: string) => Promise<ProjectAnalysisStats>;
  searchSimilarLexeme: (
    projectId: string,
    lexeme: string[]
  ) => Promise<LexicalMatch[]>;
  mergeVerbatimTag: (
    project_id: string,
    dimension: string,
    targetLabel: string,
    targetName: string,
    names: string[]
  ) => Promise<any>;
  unmergeVerbatimTag: (
    project_id: string,
    dimension: string,
    names: string[],
    setLemmaAsLabel: boolean
  ) => Promise<any>;

  fewShotTag: (
    projectId: string,
    dimensionId: string,
    modelName: string | undefined,
    modelType: ClassificationMethod | undefined,
    embeddingModel: string | undefined,
    updateDimensionModel: boolean
  ) => Promise<FewShotTaggingModelId>;

  getFewShotTaggingModel: (modelId: string) => Promise<FewShotTaggingModelView>;
  scoreATag: (tagId: string, score: number) => Promise<void>;
}

export interface ProjectContextType {
  project: ProjectView | "loading";
  projectId: string | undefined;
  projectName: string;
  dimensions: ProjectDimension[];
  refreshProject: () => void;
  refreshAnalysisStats: () => void;
  addNewClassToDimension: (dimensionId: string, className: string) => void;
  tagAddedEventCounter: number;
  analysisStats:
    | (Omit<ProjectAnalysisStats, "tokens_count" | "verbatim_count"> & {
        tokens_count: string | number;
        verbatim_count: string | number;
      })
    | "loading";
}

export const useProjectApi = function (): ProjectApi | null {
  const { token, workspaceId } = useContext(AuthUserContext) as {
    token: string;
    workspaceId: string;
    permissions: string[];
  };
  const [api, setApi] = useState(null as ProjectApi | null);

  useEffect(() => {
    if (token && workspaceId) {
      let client = new AppCLient({
        BASE: process.env.REACT_APP_APP_API_HOST,
        TOKEN: token,
      });

      setApi({
        scoreATag(tagId: string, score: number): Promise<void> {
          return client.project.tagUpdateScoreProjectTagsTagIdPost({
            tag_id: tagId,
            score,
          });
        },
        getFewShotTaggingModel(
          modelId: string
        ): Promise<FewShotTaggingModelView> {
          return client.fewShotTaggingModel.getFewShotTaggingFewshotTaggingModelModelIdGet(
            modelId
          );
        },
        fewShotTag(
          projectId: string,
          dimensionId: string,
          modelName: string | undefined,
          modelType: ClassificationMethod | undefined,
          modelEmbedding: string | undefined,
          updateDimensionModel: boolean
        ): Promise<FewShotTaggingModelId> {
          if (updateDimensionModel) {
            return client.project.updateDimensionModelProjectProjectIdDimensionsDimensionIdFewShotLearningModelUpdatePost(
              projectId,
              dimensionId
            );
          } else {
            if (!modelType) throw Error("Model type is required");
            return client.project.autocompleteDimensionWithFewshootLearningModelProjectProjectIdDimensionsDimensionIdFewshotTaggingPost(
              {
                project_id: projectId,
                dimension_id: dimensionId,
                embedding_model: modelEmbedding || "",
                model_name: modelName || "",
                classification_method: modelType,
              }
            );
          }
        },
        getProjectTags(
          project_id: string,
          page: number,
          dimension: string
        ): Promise<TagOccurencesWithRelatedTagsPaginated> {
          return client.project.searchTagsByTagProjectProjectIdSearchTagsGet(
            project_id,
            dimension,
            page
          );
        },
        previewDocument: (file: File) => {
          return client.project.previewProjectPreviewDocumentPost({
            file: file,
          });
        },
        getProjects: () => {
          return client.workspace.getWorkspaceProjectsWorkspaceWorkspaceIdProjectsGet(
            workspaceId
          );
        },
        updateProject: (projectId: string, data: { newName: string }) => {
          return client.project.renameProjectProjectProjectIdRenamePost({
            project_id: projectId,
            name: data.newName,
          });
        },
        deleteProject: async (projectId: string) => {
          await client.project.deleteProjectProjectProjectIdDelete(projectId);
        },
        createProject: async (
          name: string,
          lang: Language
        ): Promise<string> => {
          const project_id =
            await client.workspace.createProjectWorkspaceWorkspaceIdProjectPost(
              workspaceId,
              { name: name, language: lang }
            );
          return project_id.project_id;
        },
        getProject: (projectId: string) => {
          return client.project.getProjectInfoProjectProjectIdGet(projectId);
        },
        getProjectPatterns: async (
          project_id: string,
          pos_patterns: POS[][] | undefined,
          term: string | undefined,
          min_occurrences: number,
          max_occurrences: number,
          page: number,
          enabledParsing: boolean,
          semanticSearch: boolean
        ) => {
          return client.project.getProjectPatternsProjectProjectIdPatternsGet(
            project_id,
            JSON.stringify(pos_patterns),
            term,
            min_occurrences,
            max_occurrences,
            page,
            50,
            enabledParsing,
            semanticSearch
          );
        },
        addFileToProject: async (
          projectId: string,
          file: File,
          params: any
        ): Promise<void> => {
          await client.project.addDocumentProjectProjectIdDocumentsPost(
            projectId,
            {
              file: file,
            },
            params.ignore_header || false,
            params.data_column || 0,
            params.split_cell
          );
        },
        getPresets: () => {
          return client.project.getModelPresetsProjectPresetsGet();
        },
        triggerPreset: (projectId: string, presetId: string) => {
          return client.project.runPresetProjectProjectIdRunPresetPresetIdPost(
            projectId,
            presetId
          );
        },
        getHistory: (projectId: string) =>
          client.project.getProjectHistoryProjectProjectIdHistoryGet(projectId),
        getProjectParsingAnalyseUrl(projectId: string) {
          return `${process.env.REACT_APP_APP_API_HOST}/project/${projectId}/parsing_dataviz.html`;
        },

        getDocuments: (projectId: string) =>
          client.project.getDocumentsProjectProjectIdDocumentsGet(projectId),
        deleteDocument: async (documentId: string) =>
          alert("TODO: delete document not implemented yet"),
        exportDocument: async (
          document: DocumentView,
          dimensions: String[]
        ) => {
          window.open(
            process.env.REACT_APP_APP_API_HOST +
              "/project/" +
              document.project_id +
              "/documents/" +
              document.id +
              "?dimensions=" +
              dimensions.join(","),
            "_blank"
          );
        },
        importMetadata: async (document: DocumentView, metadata: string[]) => {
          notificationInfo(`Les metadonnées vont bientôt être importées..`, {
            duration: 5000,
          });
          client.project.importDocumentMetadataProjectProjectIdDocumentsDocumentIdImportMetadataPost(
            {
              project_id: document.project_id,
              document_id: document.id,
              metadata: metadata,
            }
          );
        },
        resetDimension: (projectId: string, dimensionId: string) =>
          client.project.resetDimensionWithModelProjectProjectIdResetDimensionDimensionIdPost(
            projectId,
            dimensionId
          ),
        deleteDimension: (projectId: string, dimensionId: string) =>
          client.project.deleteDimensionProjectProjectIdDimensionsDimensionsIdDelete(
            projectId,
            dimensionId
          ),
        editTag: (projectId: string, tagId: string, className: string) =>
          client.project.editTagProjectProjectIdTagTagIdPost(
            projectId,
            tagId,
            className
          ),
        deleteVerbatimTag: (
          projectId: string,
          tagId: string,
          allOccurrences: boolean = false
        ) =>
          client.project.deleteTagProjectProjectIdTagTagIdDelete(
            projectId,
            tagId,
            allOccurrences
          ),
        addAVerbatimTag: (
          projectId: string,
          verbatimId: string,
          dimension: string,
          className: string
        ) =>
          client.project.tagAVerbatimProjectProjectIdTagVerbatimPost({
            project_id: projectId,
            verbatim_id: verbatimId,
            dimension: dimension,
            class_name: className,
          }),
        putVerbatimTags: (
          projectId: string,
          verbatimId: string,
          dimension: string,
          tags: string[]
        ) =>
          client.project.putVerbatimTagsProjectProjectIdVerbatimVerbatimIdTagsPut(
            {
              project_id: projectId,
              verbatim_id: verbatimId,
              dimension: dimension,
              tags: tags,
            }
          ),
        tagAPattern: (params: TagAPattern) =>
          client.project.tagAPatternProjectProjectIdTagPatternPost(params),
        getTagPatches: (projectId: string, dimension: string) =>
          client.project.getTagPatchProjectProjectIdTagPatchGet(
            projectId,
            dimension
          ),
        applyTagPatch: (projectId: string, patchIds: string[]) =>
          client.project.applyTagPatchProjectProjectIdApplyTagPatchPost({
            project_id: projectId,
            patch_ids: patchIds,
          }),
        duplicateATag: (params: DuplicateTags) => {
          return client.project.duplicateTagsProjectProjectIdDuplicateTagsPost(
            params
          );
        },
        getWorkspaceModels: (workspaceId: string) => {
          return client.workspace.getWorkspaceModelsWorkspaceWorkspaceIdModelsGet(
            workspaceId
          );
        },
        importPatternMatchingModel: (
          projectId: string,
          modelId: string,
          modelName: string
        ) =>
          client.project.addPatternMatchingDimensionProjectProjectIdAddPatternMatchingDimensionPost(
            projectId,
            {
              model_id: modelId,
              dimension_name: modelName,
              dimension_title: modelName,
              color: ColorName.INDIGO,
            }
          ),
        importFewShotTaggingModel: (
          projectId: string,
          modelId: string,
          modelName: string
        ) =>
          client.project.importFewShotTaggingModelProjectProjectIdImportFewshotTaggingModelPost(
            {
              project_id: projectId,
              model_id: modelId,
              dimension_name: modelName,
              dimension_title: modelName,
              color: ColorName.ORANGE,
            }
          ),
        importPresetModel: (
          projectId: string,
          modelId: string,
          modelName: string
        ) =>
          client.project.runPresetProjectProjectIdRunPresetPresetIdPost(
            projectId,
            modelId
          ),
        createPatternMatchingDimension: async (
          projectId: string,
          modelName: string,
          className: string
        ) => {
          const modelId =
            await client.workspace.createPatternMatchingModelWorkspaceWorkspaceIdPatternMatchingModelPost(
              workspaceId,
              {
                name: modelName,
                patterns: [
                  {
                    name: className,
                    patterns: [],
                  },
                ],
              }
            );
          const DimId =
            await client.project.addPatternMatchingDimensionProjectProjectIdAddPatternMatchingDimensionPost(
              projectId,
              {
                model_id: modelId.model_id,
                dimension_name: modelName,
                dimension_title: modelName,
                color: ColorName.INDIGO,
              }
            );
          return DimId;
        },

        getManualCorrection: (projectId: string) =>
          client.project.getProjectManualAnnotationProjectProjectIdManualAnnotationsGet(
            projectId
          ),
        updateManualCorrection: (
          annotationId: string,
          learningScope: AnnotationLearningScope
        ) =>
          client.project.updateMannualAnnotationProjectManualAnnotationsAnnotationIdPut(
            annotationId,
            {
              learning_scope: learningScope,
            }
          ),

        getFrequencyGraph: (
          projectId: string,
          dimensions: string,
          subset: string,
          nodes: number
        ) =>
          client.project.getFrequencyGraphProjectProjectIdFrequencyGraphGet(
            projectId,
            dimensions,
            subset,
            nodes
          ),

        getProjectParsingStats: (projectId: string) =>
          client.project.getParsingStatusProjectProjectIdParsingStatusGet(
            projectId
          ),

        getMultiDimensionalPlotIframeUrl: (projectId: string, type: string) =>
          `${process.env.REACT_APP_APP_API_HOST}/project/${projectId}/plot/${type}.html`,
        getBertopicBartChart: (analyseId: string, type: string) =>
          `${process.env.REACT_APP_APP_API_HOST}/topic_modeling_job/${analyseId}/${type}.html`,
        getTopicVerbatims: (projectId: string) =>
          client.project.getVerbatimsGroupbyDimensionProjectVerbatimsProjectIdGroupbyDimensionGet(
            projectId,
            "thèmes"
          ),
        renameTopic: (
          projectId: string,
          className: string,
          newClassName: string
        ) =>
          client.project.renameProjectDimensionClassProjectProjectIdDimensionDimensionClassClassnameRenamePost(
            {
              project_id: projectId,
              dimension: "thèmes",
              classname: className,
              new_classname: newClassName,
            }
          ),
        searchSimilarLexeme: (projectId: string, words: string[]) =>
          client.project.getLexicalSimilarityProjectProjectIdSearchLexicalSimilarityPost(
            projectId,
            0.5,
            words
          ),
        mergeVerbatimTag(
          project_id: string,
          dimension: string,
          target_label: string,
          target_name: string,
          names: string[]
        ): Promise<any> {
          return client.project.mergeTokenTagProjectProjectIdMergeTokenTagPost({
            project_id,
            dimension,
            target_label,
            target_name,
            names,
          });
        },
        unmergeVerbatimTag(
          project_id: string,
          dimension: string,
          names: string[],
          set_lemma_as_label
        ): Promise<any> {
          return client.project.unmergeTokenTagsProjectProjectIdUnmergeTokenTagPost(
            {
              project_id,
              dimension,
              names,
              set_lemma_as_label,
            }
          );
        },
      });
    }
  }, [token, workspaceId]);

  return api as ProjectApi;
};
export const useProjectQuery = (params: { refetchInterval: number }) => {
  const api = useProjectApi() as ProjectApi;
  const [searchParams] = useSearchParams();
  const { projectId } = useParams() as { projectId: string };
  const [projectName, setProjectName] = useState("");
  const blacklist_freqGraph_dimension = "blacklist_frequencygraph";
  const analyseParsingDatavizUrl = `${process.env.REACT_APP_APP_API_HOST}/project/${projectId}/parsing_dataviz.html`;
  useEffect(() => {
    if (searchParams.get("name")) {
      setProjectName(searchParams.get("name") as string);
    }
  }, [searchParams]);
  return useQuery({
    queryKey: ["project", projectId],
    queryFn: async () => {
      const project = await api.getProject(projectId);
      return {
        ...project,
        dimensions: (project?.dimensions || [])
          .filter((d) => d.name !== blacklist_freqGraph_dimension)
          .sort((a, b) => a.name.localeCompare(b.name)),
        outdatedDimensions: (project?.dimensions || []).filter(
          (dim: any) => dim.outdated
        ),
        analyseParsingDatavizUrl,
      };
    },
    initialData: {
      id: projectId,
      name: projectName,
      dimensions: [],
      outdatedDimensions: [],
      analyseParsingDatavizUrl,
    },
    enabled: !!projectId && !!api,
    staleTime: 0,
    refetchInterval: params.refetchInterval,
    refetchIntervalInBackground: false,
  });
};

export const useProject = () => {
  const { projectId } = useParams() as { projectId: string };

  const api = useProjectApi() as ProjectApi;
  const queryClient = useQueryClient();
  const [projectDimensions, setProjectDimensions] = useState<
    ProjectDimension[]
  >([]);
  const blacklist_freqGraph_dimension = "blacklist_frequencygraph";

  const _project = useProjectQuery({ refetchInterval: 5000 });

  useEffect(() => {
    if (
      _project.data.dimensions.map((d) => d.name).join(",") !==
      projectDimensions.map((d) => d.name).join(",")
    ) {
      setProjectDimensions(_project.data.dimensions);
    }
  }, [_project]);

  const _ProjectAnalysisStats = useQuery({
    queryKey: ["_ProjectAnalysisStats", projectId],
    queryFn: async () => {
      const stats = await api.getProjectParsingStats(projectId);
      const total =
        stats.parsing.failed +
        stats.parsing.done +
        stats.parsing.expected +
        stats.parsing.pending;
      const analyseParsingPercentage =
        Math.floor((stats.parsing.done / total) * 100) + 1;
      return {
        ...stats,
        tokens_count: formatNumber(stats?.tokens_count),
        verbatim_count: formatNumber(stats?.verbatim_count),
        verbatim_count_number: stats?.verbatim_count,
        analyseParsingPercentage: analyseParsingPercentage,
        error_count:
          (stats?.dimensions || []).reduce(
            (acc, dim) => acc + dim.stats.failed,
            0
          ) + (stats.parsing.failed || 0),
        dimensions: (stats?.dimensions || ([] as DimensionParsingStats[]))
          .filter((d) => d.dimension !== blacklist_freqGraph_dimension)
          .sort((a, b) => a.dimension.localeCompare(b.dimension)),
        dimensionAnalysisPercentage: (stats?.dimensions || [])
          .map((dim) => {
            const total =
              dim.stats.failed +
              dim.stats.done +
              dim.stats.expected +
              dim.stats.pending +
              dim.stats.idle;
            return {
              p: Math.floor((dim.stats.done / total) * 100) + 1,
              total: total,
              done: dim.stats.done,
              dimension: dim.dimension,
            };
          })
          .filter((p) => p.p < 100)
          .sort((a, b) => a.dimension.localeCompare(b.dimension)),
      };
    },
    initialData: {
      tokens_count: "?",
      verbatim_count: "?",
      verbatim_count_number: 0,
      error_count: 0,
      analyseParsingPercentage: 100,
      dimensionAnalysisPercentage: [],
      dimensions: [] as DimensionParsingStats[],
      parsing: {
        idle: 0,
        pending: 0,
        expected: 0,
        done: 0,
        failed: 0,
      },
    },
    enabled: !!projectId && !!api,
    staleTime: 0,
    refetchInterval: 5000,
    refetchIntervalInBackground: false,
  });

  const _resetDimension = useSyncMutation(
    async (dimension: ProjectDimension) => {
      await api.resetDimension(projectId, dimension.id);
    },
    {
      onSuccess: (data, dimension) => {
        notificationInfo(`${dimension.name} est en cours d'analyse`, {
          id: dimension.id,
        });
        _ProjectAnalysisStats.refetch();
        _project.refetch();
      },
      onMutate: (dimension) => {
        notificationInfo(
          `L'analyse du project avec le modèle ${dimension.name} va bientôt démarrer..`,
          { duration: 10000, id: dimension.id }
        );
      },
    }
  );

  // prefecth patterns
  useEffect(() => {
    if (api !== null) {
      prefecthPatterns(queryClient, projectId, api, LexiquePatterns);
      prefecthPatterns(queryClient, projectId, api, NgrammesPatterns);
    }
  }, [projectId, api]);

  return {
    _project,
    projectDimensions,
    projectId,
    _ProjectAnalysisStats,
    _resetDimension,
  };
};
