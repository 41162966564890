import { ProjectApi, useProject, useProjectApi } from "../State";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import {
  ConfigurationMap,
  fileId,
  FileUploadForm,
} from "../components/FileUploadForm";
import { DocumentView } from "../../../app_client";
import { ExportDocumentModal } from "./ExportDocumentModal";
import { Card } from "flowbite-react";
import { YMButton, YMLoader, YMTable } from "../../../ym-components";
import { AiOutlineDownload } from "react-icons/ai";
import { BsDatabaseAdd } from "react-icons/bs";
import { ImportMetadataModal } from "./ImportMetadata";

export const ProjectDocuments = () => {
  const api = useProjectApi() as ProjectApi;
  const { projectId } = useProject();
  const [files, setFiles] = useState<File[]>([]);
  const [configurations, setConfigurations] = useState<ConfigurationMap>({});
  const [uploadStates, setUploadStates] = useState<{
    [key: string]: "uploading" | "done" | "error";
  }>({});
  const [documentToExport, setDocumentToExport] = useState<
    DocumentView | undefined
  >();
  const [documentToImportMetadata, setDocumentToImportMetadata] = useState<
    DocumentView | undefined
  >();

  const document = useQuery({
    queryKey: ["projectDocuments", projectId, "documents"],
    queryFn: async () => {
      return api.getDocuments(projectId);
    },
    enabled: !!api,
  });

  const onAddFiles = async () => {
    await Promise.all(
      files.map((f) => {
        uploadState(f, "uploading");
        let promise = api.addFileToProject(
          projectId,
          f,
          configurations[fileId(f)] || { split_cell: true }
        );
        promise
          .then(() => {
            uploadState(f, "done");
          })
          .catch((error: any) => {
            uploadState(f, "error");
          });
        return promise;
      })
    );
    await document.refetch();
  };

  const uploadState = (file: File, state: "uploading" | "done" | "error") => {
    setUploadStates({
      ...uploadStates,
      [fileId(file)]: state,
    });
  };

  return (
    <div>
      <ExportDocumentModal
        doc={documentToExport}
        onCloseModal={() => setDocumentToExport(undefined)}
        onExportDocument={(doc, dimensions) => {
          api.exportDocument(doc, dimensions);
          setDocumentToExport(undefined);
        }}
      />
      {documentToImportMetadata && (
        <ImportMetadataModal
          doc={documentToImportMetadata}
          onCloseModal={() => setDocumentToImportMetadata(undefined)}
          onImportMetadata={(doc, metadata) => {
            api.importMetadata(doc, metadata);
            setDocumentToImportMetadata(undefined);
          }}
        />
      )}
      <Card className={"mb-10"}>
        <div className={"format"}>
          <h2 className={"text-indigo-900"}>Ajouter des sources au projet</h2>
        </div>
        <form className="flex flex-col gap-4">
          <div className={"w-72"}></div>
          <FileUploadForm
            uploadStates={uploadStates}
            onFilesUpdated={(files, configurations) => {
              setFiles(files);
              setConfigurations(configurations);
            }}
          />
          <div className={"w-28"}>
            {files.length > 0 && (
              <YMButton text={"Ajouter"} onClick={onAddFiles} />
            )}
          </div>
        </form>
      </Card>
      <YMLoader
        message={"Chargement des documents"}
        loading={document.isFetching || !document.data}
      >
        <YMTable
          header={
            <tr>
              <th scope="col" className="px-6 py-3">
                Sources du projet
              </th>
              <th></th>
            </tr>
          }
          rows={(document.data || []).map((d) => (
            <tr
              key={d.document_name}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100"
            >
              <td
                scope="row"
                className="px-6 py-4 text-gray-900 hover:text-indigo-900  cursor-pointer whitespace-nowrap dark:text-white"
              >
                <a href={"/events/" + d.id} target="_blank" rel="noreferrer">
                  {" "}
                  {d.document_name}
                  {d.document_extension}{" "}
                </a>
              </td>
              <td>
                <div className={"flex gap-2"}>
                  <YMButton
                    text={"Exporter"}
                    icon={<AiOutlineDownload className={"h-6 w-6 pr-2"} />}
                    size={"xs"}
                    color={"white"}
                    onClick={() => setDocumentToExport(d)}
                  />
                  <YMButton
                    text={"Importer des métadonnées"}
                    icon={<BsDatabaseAdd className={"h-6 w-6 pr-2"} />}
                    size={"xs"}
                    color={"white"}
                    onClick={() => setDocumentToImportMetadata(d)}
                  />
                </div>
              </td>
            </tr>
          ))}
        />
      </YMLoader>
    </div>
  );
};
