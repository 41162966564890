import React, { useEffect, useRef, useState } from "react";
import { useApi } from "../../../api";
import { YMInput, YMLightButton } from "../../../ym-components";
import { BsBarChartLineFill } from "react-icons/bs";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import {
  AiFillCloud,
  AiOutlinePieChart,
  AiOutlineRadarChart,
} from "react-icons/ai";
import { ProjectDimension, TagType } from "../../../app_client";
import { WordCloud, WordCloudParams } from "./WordCloud";
import { VerbatimsModal } from "../components/verbatims/VerbatimModal";

type DistributionDatavizProps = {
  dimension: ProjectDimension;
  projectId: string;
  version: any;
};

export const DistributionDataviz: React.FC<DistributionDatavizProps> = ({
  version,
  dimension,
  projectId,
}) => {
  const { api } = useApi();
  const [iframeKey, setIframeKey] = useState(0);
  const [color, setColor] = useState("indigo" as string);

  const [type, setType] = useState("bar" as string);
  const [maxCategories, setMaxCategories] = useState(5 as number);

  const [wordCloudParams, setWordCloudParams] = useState<WordCloudParams>({
    wordcloudClassCount: 15,
    wordCloudFontDisparity: 0.5,
    wordCloudFontMin: 12,
    wordCloudFontMax: 36,
    height: 800,
    width: 1200,
    color: "indigo",
    classColors: {},
  });
  const cloudRef = useRef(null) as any;

  const colors = ["indigo", "blue", "red", "amber", "emerald", "gray"];

  useEffect(() => {
    setIframeKey(iframeKey + 1);
  }, [color, type, maxCategories, wordCloudParams, version]);

  // custom rules for PER/ORG/LOC
  // TODO : refactor this in a better way
  useEffect(() => {
    if (dimension.tag_type === TagType.TOKEN_CLASSIFICATION) {
      if (iframeKey == 0) {
        setType("cloud");
      }
    }
  });

  //rerender on resize
  useEffect(() => {
    const handleResize = () => {
      setIframeKey(iframeKey + 1);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // display verbatims on click
  const [verbatimModalProps, setVerbatimModalProps] = useState({
    show: false,
    subsetFilter: "",
    lemmasFilter: "",
    dimensionFilter: "",
  });
  useEffect(() => {
    const handler = (e: MessageEvent) => {
      if (e.origin !== window.location.origin) {
        console.log(e.data);
        if (e.data.startsWith("DISPLAY_VERBATIMS_BY_LEMMA_")) {
          setVerbatimModalProps({
            show: true,
            subsetFilter: "",
            dimensionFilter: dimension.name,
            lemmasFilter: e.data.split("DISPLAY_VERBATIMS_BY_LEMMA_")[1],
          });
        } else {
          setVerbatimModalProps({
            show: true,
            lemmasFilter: "",
            dimensionFilter: "",
            subsetFilter: e.data.split("DISPLAY_VERBATIMS_")[1],
          });
        }
      }
    };
    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, []);

  // @ts-ignore
  return (
    <>
      <VerbatimsModal
        show={verbatimModalProps.show}
        onClose={() =>
          setVerbatimModalProps({
            show: false,
            lemmasFilter: "",
            dimensionFilter: "",
            subsetFilter: "",
          })
        }
        subsetFilter={verbatimModalProps.subsetFilter}
        lemmasFilter={verbatimModalProps.lemmasFilter}
        dimensionFilter={verbatimModalProps.dimensionFilter}
      />
      <div className="flex flex-row space-x-4 border border-gray-300 rounded-sm">
        <div className="border-r border-gray-300">
          <div className="text-center space-y-3 pt-2 px-2">
            <YMLightButton
              label={""}
              icon={<BsBarChartLineFill />}
              onClick={() => setType("bar")}
            />
            <YMLightButton
              label={""}
              icon={<MdOutlineSpaceDashboard />}
              onClick={() => setType("treemap")}
            />
            <YMLightButton
              label={""}
              icon={<AiOutlineRadarChart />}
              onClick={() => setType("polar")}
            />
            <YMLightButton
              label={""}
              icon={<AiOutlinePieChart />}
              onClick={() => setType("pie")}
            />
            <YMLightButton
              label={""}
              icon={<AiFillCloud />}
              onClick={() => setType("cloud")}
            />
          </div>
        </div>
        <div className="w-full p-5 relative">
          <div className="pb-5 mb-5 border-b border-gray-200">
            {api &&
              dimension.name != "" &&
              ["bar", "treemap", "polar", "pie"].indexOf(type) != -1 && (
                <div className="flex flex-col gap-4">
                  <div className={"flex gap-2 items-center"}>
                    <div className="text-md font-bold text-indigo-900 mb-1">
                      {" "}
                      Couleur
                    </div>
                    {colors.map((c, i) => (
                      <div
                        onClick={() => setColor(c)}
                        className={`h-5 w-5 cursor-pointer ring-2 bg-${c}-500 hover:bg-${c}-800 rounded-3xl ring-gray-200`}
                      ></div>
                    ))}
                  </div>
                  <div>
                    <YMInput
                      label={"Nombre de catégorie max"}
                      type="number"
                      value={maxCategories}
                      onChange={(v) => setMaxCategories(parseInt(v))}
                    />
                  </div>
                </div>
              )}
            {api && type == "cloud" && (
              <WordCloud dimension={dimension} handleParamsChange={setWordCloudParams} />
            )}
            <div className="items-center mt-4">
              {api &&
                dimension.name != "" &&
                ["bar", "polar", "pie"].indexOf(type) != -1 && (
                  <iframe
                    key={iframeKey + type}
                    style={{ width: "100%", height: "420px" }}
                    src={api.project_dimension_plot_iframe_url(
                      projectId,
                      dimension.name,
                      type,
                      color,
                      maxCategories
                    )}
                  />
                )}
              {api &&
                dimension.name != "" &&
                ["treemap"].indexOf(type) != -1 && (
                  <iframe
                    key={iframeKey + "bar"}
                    style={{ width: "1000px", height: "1000px" }}
                    src={api.project_dimension_plot_iframe_url(
                      projectId,
                      dimension.name,
                      type,
                      color,
                      maxCategories
                    )}
                  />
                )}
              {api && dimension.name != "" && (
                <div
                  key={iframeKey + "wordcloud"}
                  style={{ width: "100%", height: "420px" }}
                  ref={cloudRef}
                  className={
                    "flex justify-center w-100 " +
                    (type == "cloud" ? "visible" : "hidden")
                  }
                >
                  <object
                    className="wordcloud"
                    key={cloudRef.current?.offsetWidth}
                    data={api.project_dimension_wordcloud_image_url(
                      projectId,
                      dimension.name,
                        {...wordCloudParams, height: cloudRef.current?.offsetHeight, width: cloudRef.current?.offsetWidth}
                    )}
                    type="image/svg+xml"
                  ><text/></object>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
