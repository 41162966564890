/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { EmbeddingService } from './services/EmbeddingService';
import { FewShotTaggingModelService } from './services/FewShotTaggingModelService';
import { KernelEndpointService } from './services/KernelEndpointService';
import { NlpJobsService } from './services/NlpJobsService';
import { PatternMatchingModelService } from './services/PatternMatchingModelService';
import { ProjectService } from './services/ProjectService';
import { RateLimitInfoService } from './services/RateLimitInfoService';
import { TopicModelingJobService } from './services/TopicModelingJobService';
import { UserInfoService } from './services/UserInfoService';
import { WorkspaceService } from './services/WorkspaceService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppCLient {

    public readonly embedding: EmbeddingService;
    public readonly fewShotTaggingModel: FewShotTaggingModelService;
    public readonly kernelEndpoint: KernelEndpointService;
    public readonly nlpJobs: NlpJobsService;
    public readonly patternMatchingModel: PatternMatchingModelService;
    public readonly project: ProjectService;
    public readonly rateLimitInfo: RateLimitInfoService;
    public readonly topicModelingJob: TopicModelingJobService;
    public readonly userInfo: UserInfoService;
    public readonly workspace: WorkspaceService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '2.5.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.embedding = new EmbeddingService(this.request);
        this.fewShotTaggingModel = new FewShotTaggingModelService(this.request);
        this.kernelEndpoint = new KernelEndpointService(this.request);
        this.nlpJobs = new NlpJobsService(this.request);
        this.patternMatchingModel = new PatternMatchingModelService(this.request);
        this.project = new ProjectService(this.request);
        this.rateLimitInfo = new RateLimitInfoService(this.request);
        this.topicModelingJob = new TopicModelingJobService(this.request);
        this.userInfo = new UserInfoService(this.request);
        this.workspace = new WorkspaceService(this.request);
    }
}

