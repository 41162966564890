/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateNewPatternMatchingModel } from '../models/CreateNewPatternMatchingModel';
import type { ModelId } from '../models/ModelId';
import type { PatternMatchingModelProjection } from '../models/PatternMatchingModelProjection';
import type { UpdatePatternMatchingModel } from '../models/UpdatePatternMatchingModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PatternMatchingModelService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Models
     * @returns PatternMatchingModelProjection Successful Response
     * @throws ApiError
     */
    public getModelsPatternMatchingModelGet(): CancelablePromise<Array<PatternMatchingModelProjection>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pattern_matching_model/',
        });
    }

    /**
     * New Patter Matching Model
     * @param requestBody
     * @returns ModelId Successful Response
     * @throws ApiError
     */
    public newPatterMatchingModelPatternMatchingModelPost(
        requestBody: CreateNewPatternMatchingModel,
    ): CancelablePromise<ModelId> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pattern_matching_model/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Model
     * @param modelId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateModelPatternMatchingModelModelIdPost(
        modelId: string,
        requestBody: UpdatePatternMatchingModel,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pattern_matching_model/{model_id}/',
            path: {
                'model_id': modelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Mode
     * @param modelId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteModePatternMatchingModelModelIdDelete(
        modelId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/pattern_matching_model/{model_id}/',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Model
     * @param modelId
     * @returns PatternMatchingModelProjection Successful Response
     * @throws ApiError
     */
    public getModelPatternMatchingModelModelIdGet(
        modelId: string,
    ): CancelablePromise<PatternMatchingModelProjection> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/pattern_matching_model/{model_id}',
            path: {
                'model_id': modelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
